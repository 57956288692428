<template>
  <div>
    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>Você tem alguma dúvida?</h2>
          <b-card-text class="mb-3">
            Entre em contato com a gente!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
              :src="require('@/assets/images/icons/whatsapp.png')"
            />
            <h4
              v-if="empresa.suporteTelefone"
            >
              {{ empresa.suporteTelefone }}
            </h4>
            <h4
              v-if="empresa.linkWhatsapp"
            >
              <b-link
                :href="encodeURI(empresa.linkWhatsapp.replace('$$nome$', userData.displayName))"
                target="_blank"
              >
                Clique aqui para solicitar suporte
              </b-link>
            </h4>
            <span class="text-body">Nós estamos sempre felizes em ajudar!</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->

  </div>
</template>

<script>
import {
  BCard, BCardText, BAvatar, BLink,
} from 'bootstrap-vue'
import utilsMixin from '@/mixins/utilsMixin'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BCard,
    BCardText,
    BAvatar,
    BLink,
  },

  mixins: [utilsMixin],

  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      data: {},
    }
  },

  setup() {
    const { empresa } = useAppConfig()
    return {
      empresa,
    }
  },

  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
